import React from 'react';
import autobind from "../../functions/autobind";
import _uniqueId from 'lodash/uniqueId';
import _snakeCase from 'lodash/snakeCase'

export class InputField extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.id = _uniqueId(_snakeCase(this.props.label) + '_');
        this.state = { value: this.props.value || '' };

        this.validators = this.props.validators || [];


        this.trixRef = React.createRef();
        this.trixContentRef = React.createRef();

    }

    componentDidMount() {
        if (this.trixRef.current) {
            this.trixRef.current.addEventListener('trix-change', () => {
                this.setState({ value: this.trixContentRef.current.value, error: null });
                if (this.props.onChange) {
                    this.props.onChange(this.trixContentRef.current.value);
                }
            });
        }
    }

    handleChange(e) {
        this.setState({ value: e.target.value, error: null });
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        let label = <label htmlFor={this.id} className={this.props.error === undefined ? '' : 'error'}>{[this.props.label, this.props.error].join(' ')}</label>
        let input = this.props.type === 'trix' ?
            <><input id={this.id} type="hidden" value={this.state.value} ref={this.trixContentRef} /><trix-editor ref={this.trixRef} input={this.id} class='trix-content' /></>
            :
            <input id={this.id} className={this.props.error === undefined ? '' : 'error'} type={this.props.type || "text"} value={this.state.value} readOnly={this.props.readOnly} onChange={this.handleChange} />




        if (this.props.labelPosition === 'none') {
            return input
        } else if (this.props.labelPosition === 'after') {
            return <>{input}{label}</>
        } else {
            return <>{label}{input}</>
        }

    }
}
