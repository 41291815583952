import { css } from 'styled-components';

/* Deprecated */
const breakpoints = {
    small: '640px',
    medium: '960px',
    large: '1200px',
    xLarge: '1600px'
};

export const respondTo = {
    mobile: (...args) => css`@media only screen and (max-width: ${breakpoints.small}) { ${css(...args)}; }`,
    medium: (...args) => css`@media only screen and (min-width: ${breakpoints.small + 1}) and (max-width: ${breakpoints.medium}) { ${css(...args)}; }`,
    normal: (...args) => css`@media only screen and (min-width: ${breakpoints.medium + 1}) and (max-width: ${breakpoints.large}) { ${css(...args)}; }`,
    wide: (...args) => css`@media only screen and (min-width: ${breakpoints.large + 1}) { ${css(...args)}; }`
}
/* End Deprecated */

export const widthBreakpoints = {
    small: 640,
    medium: 960,
    large: 1200,
    xLarge: 1600
};

export const mediaScreen = {
    mobile: `@media only screen and (max-width: ${widthBreakpoints.small}px)`,
    medium: `@media only screen and (min-width: ${widthBreakpoints.small + 1}px) and (max-width: ${widthBreakpoints.medium}px)`,
    normal: `@media only screen and (min-width: ${widthBreakpoints.medium + 1}px) and (max-width: ${widthBreakpoints.large}px)`,
    wide: `@media only screen and (min-width: ${widthBreakpoints.large + 1}px) and (max-width: ${widthBreakpoints.xLarge}px)`,
    extraWide: `@media only screen and (min-width: ${widthBreakpoints.xLarge + 1}px)`
}

export const ellipsisClamp = type => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const appearanceNone = () => css`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`