import React from 'react';
import autobind from "../../functions/autobind";


export class Button extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = { value: this.props.value, disabled: this.props.disabled || false };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({disabled: nextProps.disabled || false})
    }

    render() {
        return (
            <input onClick={this.props.onClick} className={this.props.className} id={this.props.id} type={this.props.type || "submit"} value={this.state.value} disabled={this.state.disabled} />
        );
    }
}
