import React from 'react';

export function Row(props){
    return (
        <div style={{...props.style}} className={["form-row",props.className].join(' ')}>
            {props.children}
        </div>
    )
}

export function Column(props){
    return (
        <div style={props.style} className={["form-column",props.className].join(' ')}>
            {props.children}
        </div>
    )
}

export function CheckboxTip(props){
    return (
        <Row><Column className="checkbox-tip">{props.children}</Column></Row>
    )
}
