import React, {useEffect, useMemo, useState} from 'react';
import {Column, Row} from "./forms/Form";
import {Dashboard, DragDrop, StatusBar} from '@uppy/react'


import Uploader, {KnownTypes, BlobURL} from "../functions/Uploader";
import styled from 'styled-components';
import {parameterize} from 'inflected';


export const AttachmentLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 50px; 
  background-color: #fff;
  height: auto;
`
export const AttachmentIcon = styled.div`
  width: 100px;
  height: 100px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: ${props => (props.imageURL ? 
          `url(${props.imageURL})` : 
          props.section && props.section === "course" ? `url("/assets/svg/attachments_icon_course.svg")` : `url("/assets/svg/attachments_icon.svg")`)};
  
`


export const AttachmentText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  height: auto;
  white-space: nowrap;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  border-radius: 5px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;


  background-color: ${props => {
    switch (props.section) {
      case 'course':
        return 'var(--color-course-purple)';
      default:
        return 'var(--color-link)';
    }}}}
  
  
  &:hover{
    //background-color: var(--color-link_hover);
    color:white;
  }
`

export const AttachmentCancel = styled.a`
    margin-left:10px;
    background-image: url("/assets/icons/icon_close_blue.png");
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
`

const AddButton = styled.a`
    font-size: 1.6rem;
    font-weight: bold;
    color: #0087C4;
    cursor: pointer;
    border: 1px solid #cccccc;
    height: 40px;
    min-width: 160px;
    margin-left: 10px !important;
    width: auto;
    justify-content: center;
    display: flex;
    flex: 0 0 auto;
    align-self: flex-end;
    justify-self: flex-end;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: #FFF;
    padding: 0 10px;
`

export const AddAttachmentButtonBase = styled(AddButton)`
    border: 1px solid ${props => {
        switch (props.section) {
            case 'course':
                return 'var(--color-course-purple)';
            case 'variable':
                return 'var(--color-variable)';
            case 'organization':
                return 'var(--color-deep-blue)';
            default:
                return 'var(--color-deep-blue)';
        }
    }};
    color: white;
    background-color: ${props => {
        switch (props.section) {
            case 'course':
                return 'var(--color-course-purple) !important';
            case 'variable':
                return 'var(--color-variable)';
            case 'organization':
                return 'var(--color-deep-blue)';
            default:
                return 'var(--color-deep-blue)';
        }
    }};
    transition: background-color 0.2s, color 0.2s;
    margin-left: 0;
    
    &:hover {
      color: white;
    }
`;

export const AddAttachmentButton = (props) => <AddAttachmentButtonBase section={props.section} {...props}>{props.title}</AddAttachmentButtonBase>


export const AttachmentManager = (props) => {
    const [attachments, setAttachments] = useState(props.attachments || []);

    useEffect(() => () => uploader.uppy.close(), []);

    const uploader = new Uploader({
        label: props.label || "",
        limit: props.limit || 1,
        directUpload: true,
        autoProceed: true,
        resetOnComplete: props.resetOnComplete || false,
        allowedMimeTypes: props.types || KnownTypes,
        maxFileSizeInMB: props.maxFileSizeInMB || 5,
        onError: props.onError,

        onThumb: (file, preview) => { },
        onRemoved: props.onRemoved,
        onUpload: props.onUpload,
        onUploadSuccess: (file, response) => {
            if (attachments.length < props.limit) {
                const newAttachment = {
                    key: response.key,
                    signed_id: response.signed_id,
                    //NOTE: We can only do this because operating systems still rely on file extensions even though they don't have to
                    filename: `${parameterize(file.name.split(".")[0], {
                        preserveCase: true,
                        separator: '_'
                    })}.${file.name.split(".").pop()}`,
                    url: BlobURL.replace(":signed_id", response.signed_id).replace(":file", encodeURIComponent(file.name))
                }
                let valid = true
                if (props.validate) {
                    valid = props.validate(newAttachment)
                }

                if (valid) {
                    setAttachments(prev => [...prev, newAttachment]);
                    props.onUploadSuccess && props.onUploadSuccess(file, response, newAttachment)
                    props.onAdded && props.onAdded(newAttachment)
                }
            }
        },
        onComplete: props.onComplete,
    })

    const dashboard = dashboardProps => useMemo(() => (
        <Dashboard
            {...dashboardProps}
            uppy={uploader.uppy}
            plugins={dashboardProps?.plugins || ['Webcam']}
            inline={true}
            width={dashboardProps?.width || '95%'}
            proudlyDisplayPoweredByUppy={false}
            showLinkToFileUploadResult={false}
            showSelectedFiles={true}
            hideUploadButton={true}
            hideRetryButton={true}
            hidePauseResumeButton={true}
        />
    ), [dashboardProps, uploader.uppy]);


    const dnd = dndProps => {
        return <DragDrop {...dndProps} uppy={uploader.uppy}/>
    }

    const attachmentsRow = () => (
        <Row className="attachments" style={{fontSize: '4em', flexWrap: 'wrap'}}>
            {attachments.map((a, i) => (
                <div style={{padding: '10px'}} key={a.signed_id}>
                    <span className="attachment">{a.filename}
                        <a onClick={() => remove(i)} className="remove"/>
                    </span>
                </div>
            ))}
        </Row>
    )

    const remove = (index) => {
        setAttachments(prev => prev.filter((a, i) => i !== index));
        props.onRemoved && props.onRemoved(attachments[index]);
        reset();
    }

    const statusBar = () => (
        <StatusBar uppy={uploader.uppy} hideUploadButton hideAfterFinish showProgressDetails />
    )

    const reset = () => uploader.uppy.cancelAll()

    const renderFields = rfProps => props.limit === 1 ?
        attachments.map((a, i) => <input key={a.signed_id} type="hidden" ref={rfProps.register}
                                         name={`${rfProps.name}[signed_id]`} value={a.signed_id}/>)
        :
        attachments.map((a, i) => <input key={a.signed_id} type="hidden" ref={rfProps.register}
                                         name={`${rfProps.name}[${i}][signed_id]`} value={a.signed_id}/>)

    return props.render({
        dnd:dnd,
        dashboard: dashboard,
        renderFields: renderFields,
        statusBar: statusBar,
        attachmentsRow: attachmentsRow,
        reset: reset,
        remove: remove,
    })
}
