import {Uppy, Webcam, ThumbnailGenerator, XHRUpload, Informer, StatusBar, Compressor} from 'uppy';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor'
import React from "react";
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'
import {parameterize} from 'inflected';
import '@uppy/core/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

export const ImageTypes =
    [
        'image/*',
    ]

export const VideoTypes = ['video/*']
export const AudioTypes = ['audio/*']
export const CompressTypes = [
    'application/*.zip',
    "application/zip",
    "application/x-zip-compressed",
    "multipart/x-zip"
]

export const DocumentTypes = [
    "application/*.pptx",
    "application/*.doc",
    "application/*.xls",
    "application/*.xlsx",
    "application/msword",
    "application/rtf",
    "application/vnd.ms-powerpoint",
    "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    "application/vnd.ms-powerpoint.template.macroEnabled.12",
    "application/vnd.ms-word.document.macroEnabled.12",
    "application/vnd.ms-word.template.macroEnabled.12",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.template",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "image/heic",
    "text/rtf",
    'application/*.docx',
    'application/msword',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessing',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    "xlsx",
    "xls",
    "text/csv",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
]

export const KnownTypes = TypeCollection(ImageTypes, VideoTypes, AudioTypes, DocumentTypes)

// This is just a temporary change, to make the upload field work
export const BlobURL = document.querySelector("meta[name='blobs-redirect-url']") ? document.querySelector("meta[name='blobs-redirect-url']").getAttribute("content") : "/rails/active_storage/blobs/redirect/:signed_id/:file"


export function TypeCollection(...types) {
    return types.flat();
}

export default class Uploader {

    constructor(options) {

        const defaults = {
            label: "",
            limit: 1,
            maxFileSizeInMB: 1,
            directUpload: true,
            autoProceed: false,
            resetOnComplete: true,
            allowedMimeTypes: ImageTypes,
            endpoint: '/upload/profile_picture',
            onError: (error) => {
                console.error(error.stack);
            },

            onThumb: (file, preview) => {
            },
            onFileAdded: file => {
            },
            onFileRemoved: (file, reason) => {
            },
            onUpload: data => {
            },
            onUploadSuccess: (file, response) => {
            },
            onEditorComplete: (updatedFile) => {
            },
            onComplete: result => {
            },
            editor: false,

        }
        this.config = Object.assign(defaults, options);

        this.uppy = new Uppy({
            restrictions: {
                maxNumberOfFiles: this.config.limit,
                maxFileSize: this.config.maxFileSizeInMB * 1048576,
                allowedFileTypes: this.config.allowedMimeTypes
            },
            autoProceed: this.config.autoProceed,
            onBeforeFileAdded: (file) => {
                const name = `${parameterize(file.name.split(".")[0], { preserveCase: true, separator: '_' })}.${file.name.split(".").pop()}`
                Object.defineProperty(file.data, 'name', {
                    writable: true,
                    value: name
                });
                return {...file, name, meta: {...file.meta, name}}
            }
        })
            .use(Dashboard, {
                target: '#drag-drop-area'
            })
            .use(Informer, {
                target: Uppy.InformerDashboard,
                replaceTargetContent: true,
            })
            // .use(Informer)
            .use(StatusBar, {
                hideAfterFinish: true,
                hideUploadButton: true,
                showProgressDetails: true,
            })
            .use(ThumbnailGenerator, {thumbnailWidth: 600, waitForThumbnailsBeforeUpload: true})
            .use(Webcam, {countdown: false, onBeforeSnapshot: () => Promise.resolve(), modes: ['picture']})
            .on('thumbnail:generated', this.config.onThumb)
            .on("file-editor:complete", this.config.onEditorComplete)
            .on('file-added', this.config.onFileAdded)
            .on('file-removed', this.config.onFileRemoved)
            .on('restriction-failed',(file, error)=>{
                this.config.onError(error)
            })
            .on('upload', this.config.onUpload)
            .on('upload-success', this.config.onUploadSuccess)
            .on('complete', (result) => {
                if (this.config.resetOnComplete) {
                    this.uppy.cancelAll();
                }
                this.config.onComplete(result);
            })

        if (this.config.directUpload) {
            this.uppy.use(ActiveStorageUpload, {
                // This is just a temporary change, to make the upload field work
                directUploadUrl: document.querySelector("meta[name='direct-upload-url']") ? document.querySelector("meta[name='direct-upload-url']").getAttribute("content") : "/rails/active_storage/direct_uploads"
            })
        } else {
            this.uppy.use(XHRUpload, {
                endpoint: this.config.endpoint,
                bundle: false,
                headers: {"X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")}
            })
        }

        if (this.config.editor) {
            this.uppy.use(ImageEditor, {
                id: 'ImageEditor',
                quality: 0.8,
                cropperOptions: {
                    viewMode: 1,
                    background: true,
                    autoCropArea: 1,
                    responsive: true,
                    croppedCanvasOptions: {},
                },
                actions: {
                    revert: true,
                    rotate: true,
                    granularRotate: true,
                    flip: true,
                    zoomIn: true,
                    zoomOut: true,
                    cropSquare: true,
                    cropWidescreen: true,
                    cropWidescreenVertical: true,
                },
            })
        }

    }


}
