import React from 'react';

export class Form extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <form onChange={this.props.onChange} className={this.props.className} onSubmit={this.props.onSubmit}>
                {this.props.children}
            </form>

        );
    }
}

export {InputField} from "./InputField";
export {Button} from "./Button"
export {Row,Column,CheckboxTip} from "./Layout"

